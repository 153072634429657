import React from 'react';
import { markdownToHTML } from '@/utils/markdown-to-html';
import ButtonImage from '@/components/button-image';
import classNames from 'classnames';
import { Heading } from '@alto-avios/alto-ui';

import { CardProperties } from './card.types';
import styles from './card.module.scss';

export const TextBlock = ({
  className = '',
  textBlock,
  textColour = 'white',
  backgroundColour = '',
}: {
  className?: string;
  textBlock: CardProperties['textBlock'];
  textColour?: CardProperties['textColour'];
  backgroundColour?: CardProperties['backgroundColour'];
}) => {
  if (!textBlock) {
    return null;
  }
  return (
    <div
      data-testid="wrapper-id"
      className={classNames(
        styles['text-wrapper'],
        styles[`text-wrapper--${textColour}`],
        styles[`card--${backgroundColour}`],
        className,
      )}
    >
      {textBlock?.pillLabel && (
        <span className={classNames(styles['text-wrapper__pill'])}>
          {textBlock.pillLabel}
        </span>
      )}
      {textBlock?.heading && textBlock?.headingTag && (
        <div
          className={classNames(
            styles[`text-wrapper--align-${textBlock.headingTextAlign}`],
          )}
        >
          <Heading as={textBlock.headingTag} size={textBlock.headingSize}>
            {textBlock.heading}
          </Heading>
        </div>
      )}
      {textBlock?.body && (
        <div
          className={classNames(
            styles[`text-wrapper--align-${textBlock.bodyTextAlign}`],
          )}
          dangerouslySetInnerHTML={{
            __html: markdownToHTML(textBlock.body),
          }}
        />
      )}
      {textBlock.buttonsCollection.length > 0 && (
        <div className={styles['text-wrapper__buttons-wrapper']}>
          {textBlock.buttonsCollection.map(component => (
            <ButtonImage key={component.id} {...component} />
          ))}
        </div>
      )}
    </div>
  );
};
