import { ButtonAsButton } from '@/components/inputs/button/button.types';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';

export enum CARD_VARIANT {
  PROMOTIONAL_CARD = 'promotional-card-image-right',
  ICON_CARD = 'icon-simple',
  CARD_CTA_IMAGE_RIGHT = 'card-cta-image-right',
  CARD_CTA_IMAGE_LEFT = 'card-cta-image-left',
  PARTNER_INLINE_CARD = 'partner-inline-card',
}

export type CardProperties = {
  textColour: 'primary' | 'white' | 'black';
  backgroundColour: string;
  icon: keyof typeof ICON_VARIANT;
  variant: CARD_VARIANT;
  image: {
    title: string;
    url: string;
    description: string;
    width?: number;
    height: number;
  } | null;
  textBlock?: TextBlock;
  cardLink?: string;
  isStorybook?: boolean;
  partner: Array<{
    name: string;
    slug: string;
    logoSrc: string;
    rate: string;
    wasRate: string;
    wasRatePrefix: string;
    upToPrefix: string;
    fromPrefix: string;
    destinationUrl: string;
  }> | null;
};

export type TextBlock = {
  contentType: string;
  internalName: string;
  pillLabel?: string;
  headingTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headingSize?: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
  heading: string;
  headingTextAlign: string;
  bodyTextAlign: string;
  buttonsCollection: ButtonAsButton[];
  body: string;
};
