import React from 'react';
import { CardProperties } from '@/components/integrated/card/card.types';
import { appendImageFormatParameters } from '@/utils/contentful-image-paths/contentful-image-paths';
import classNames from 'classnames';

import { TextBlock } from '@/components/integrated/card/text-block';
import styles from '../card.module.scss';
import NextImage from '../../../data-display/image/image';

const cardCtaImageDimensions = {
  width: 840,
  height: 800,
};
const CardCtaImage = (properties: CardProperties) => {
  const {
    image,
    textBlock,
    textColour,
    variant,
    backgroundColour,
    isStorybook = false,
  } = properties;

  const imageURL = image
    ? appendImageFormatParameters({
        url: image.url,
        maxWidth: cardCtaImageDimensions.width,
        maxHeight: cardCtaImageDimensions.height,
        quality: 75,
      })
    : '';

  return (
    <div
      className={classNames(
        styles.card,
        styles[`card__${variant}`],
        styles[`card--${backgroundColour}`],
      )}
      data-testid="card-id"
    >
      {textBlock && (
        <TextBlock
          textBlock={{
            ...textBlock,
            headingTextAlign: 'left',
            bodyTextAlign: 'left',
          }}
          textColour={textColour}
          className={styles[`card__${variant}__text-wrapper`]}
        />
      )}
      {image?.url && (
        <NextImage
          src={imageURL}
          alt={image.description ?? ''}
          width={cardCtaImageDimensions.width}
          height={cardCtaImageDimensions.height}
          className={styles[`card__${variant}__image-wrapper`]}
          isStorybook={isStorybook}
        />
      )}
    </div>
  );
};

export default CardCtaImage;
