export interface ImageFormatParameters {
  url: string;
  maxWidth?: number;
  maxHeight?: number;
  focus?: string;
  format?: string;
  flags?: string;
  quality?: number;
  fit?: string;
}

export const appendImageFormatParameters = ({
  url,
  maxWidth,
  maxHeight,
  focus = 'center',
  format = 'jpg',
  flags = 'progressive',
  quality = 75,
  fit = 'fill',
}: ImageFormatParameters) => {
  const parameters = [
    `fm=${format}`,
    `fl=${flags}`,
    `q=${quality}`,
    `fit=${fit}`,
    `f=${focus}`,
    maxWidth ? `w=${maxWidth}` : '',
    maxHeight ? `h=${maxHeight}` : '',
  ].filter(Boolean);

  return `${url}?${parameters.join('&')}`;
};
