import classNames from 'classnames';
import React, { useState } from 'react';
import {
  SwitchTabItemProperties,
  SwitchTabProperties,
} from '@/components/integrated/switch-tab/switch-tab.types';
import styles from './switch-tab.module.scss';

export const SwitchTabItem: React.FC<SwitchTabItemProperties> = ({
  label,
  children,
  className,
}) => (
  <div
    className={className}
    role="tabpanel"
    aria-labelledby={`tab-${label}`}
    id={`panel-${label}`}
  >
    {children}
  </div>
);

export const SwitchTab = ({
  children,
  activeTabIndex = 0,
}: SwitchTabProperties) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const tabs = React.Children.toArray(children).filter(
    child => React.isValidElement(child) && child.type === SwitchTabItem,
  ) as Array<React.ReactElement<SwitchTabItemProperties>>;

  return (
    <div className={styles['switch-tabs']}>
      <nav className={styles['switch-tabs__tabs-wrapper']}>
        <ul className={styles['switch-tabs__tabs-wrapper__tab-list']}>
          {tabs.map((tab: { props: { label: string } }, index: number) => (
            <li key={`tab-${tab.props.label}`}>
              <button
                type="button"
                key={`tab-btn-${tab.props.label}`}
                role="tab"
                id={`tab-${tab.props.label}`}
                aria-controls={`panel-${tab.props.label}`}
                aria-selected={activeTab === index}
                onClick={() => handleTabClick(index)}
                className={classNames(
                  styles['switch-tabs__tabs-wrapper__tab-list__tab-button'],
                  {
                    [styles[
                      'switch-tabs__tabs-wrapper__tab-list__tab-button--active'
                    ]]: activeTab === index,
                  },
                )}
              >
                {tab.props.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {tabs[activeTab]}
    </div>
  );
};
