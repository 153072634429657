import ButtonImage from '@/components/button-image';
import Accordion from '@/components/data-display/accordion/accordion';
import ArticleStory from '@/components/integrated/article-story';
import ContentGrid from '@/components/integrated/content-grid';
import Section from '@/components/integrated/section';
import SuperHero from '@/components/integrated/superhero/superhero';
import TitleCopyCtaBlock from '@/components/integrated/title-copy-cta-block';
import VersatileCard from '@/components/integrated/versatile-card/versatile-card';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import useHandleFavourites from '@/hooks/use-handle-favourites';
import MemberGetMember from '@/modules/member-get-member';
import PartnerList from '@/modules/partner/partner-list';
import { TILE_TYPE } from '@/modules/partner/partner-list/partner-list.types';
import PartnersSection from '@/modules/partner/partners-section/partners-section';
import { PARTNERS_SECTION_REFERENCE } from '@/modules/partner/partners-section/partners-section.types';
import {
  CONTENT_TYPES,
  SECTION_VISIBILITY_FOR_SESSION,
  VersatileCardFormatted,
} from '@/utils/contentful-data-formatters/contentful-data-formatters.types';
import { markdownToHTML } from '@/utils/markdown-to-html';
import { CLICK_LOCATION } from '@/utils/register-partner-click/register-partner-click.types';
import { useUser } from '@auth0/nextjs-auth0/client';
import classNames from 'classnames';
import { Fragment } from 'react';
import Card from '@/components/integrated/card';
import Container from '@/components/integrated/container';
import TextBlock from '@/components/integrated/text-block/text-block';
import CarouselFavouritesRecentlyViewed from '@/modules/carousel-favourites-recently-viewed/carousel-favourites-recently-viewed';
import styles from './contentful-section.module.scss';
import type {
  ContainerFormatted,
  ContentRowFormatted,
  ContentRowRenderProperties,
  ContentfulSectionRenderProperties,
  SectionFormatted,
} from './contentful-section.types';

const ContentRowRender = ({
  contentRow,
  ...gridProperties
}: ContentRowRenderProperties) => {
  const { user } = useUser();
  const { isFavourite, toggleFavourite } = useHandleFavourites();

  const featuresToggles = useFeatureTogglesContext();

  return (
    <ContentGrid {...gridProperties}>
      {contentRow.items.map((component: any) => {
        switch (component.contentType) {
          case CONTENT_TYPES.VersatileCard: {
            const showFavourite =
              user &&
              !featuresToggles.includes(FEATURE_TOGGLE_LIST.NO_FAVS) &&
              component.content.showPartnerFavouriteToggle;

            return (
              <VersatileCard
                key={component.id}
                {...component}
                favourite={{
                  favouritesLabels: component.content.partner?.favouritesLabels,
                  showFavourite,
                  favouriteId:
                    isFavourite(component.content.partner?.mechanicId) ??
                    undefined,
                  toggleFavourite,
                }}
                id={component.htmlId}
              />
            );
          }
          case CONTENT_TYPES.TitleCopyCtaBlock: {
            return (
              <TitleCopyCtaBlock
                key={component.id}
                id={component.htmlId}
                headingOne={component.headingOne}
                headingOneColor={component.headingOneColor}
                headingOneTextAlign={component.headingOneTextAlign}
                headingTwo={component.headingTwo}
                headingTwoColor={component.headingTwoColor}
                headingTwoTextAlign={component.headingTwoTextAlign}
                headingThree={component.headingThree}
                headingThreeColor={component.headingThreeColor}
                headingThreeTextAlign={component.headingThreeTextAlign}
                headingFour={component.headingFour}
                headingFourColor={component.headingFourColor}
                headingFourTextAlign={component.headingFourTextAlign}
                description={component.description}
                descriptionColor={component.descriptionColor}
                descriptionTextAlign={component.descriptionTextAlign}
                overrideDescriptionFontSize={
                  component.overrideDescriptionFontSize
                }
                linkPrimary={component.linkPrimary}
                linkSecondary={component.linkSecondary}
                openLinksInNewTab={component.openLinksInNewTab}
                primaryCta={component.primaryCta}
                secondaryCta={component.secondaryCta}
                openCtasInNewTab={component.openCtasInNewTab}
                inlineButtons={component.inlineButtons}
                isFullBleed={component.isFullBleed}
              />
            );
          }
          case CONTENT_TYPES.ArticleStory: {
            return (
              <Fragment key={component.id}>
                <ArticleStory
                  key={component.id}
                  id={component.htmlId}
                  imageSrc={component.imageSrc}
                  imageAlt={component.imageAlt}
                  youtubeID={component.youtubeId}
                  youtubeTitle={component.youtubeTitle}
                  youtubeAutoPlay={component.youtubeAutoPlay}
                  content={component.content}
                />
              </Fragment>
            );
          }
          case CONTENT_TYPES.PartnerList: {
            return (
              <PartnerList
                partnerList={
                  component.tileType === TILE_TYPE.Hero
                    ? component.partnerList.filter(
                        (partner: VersatileCardFormatted) =>
                          partner?.content?.partner?.partnerId ??
                          partner?.content?.partner?.mechanicId,
                      )
                    : component.partnerList
                }
                listType={component.listType}
                tileType={component.tileType}
              />
            );
          }
          case CONTENT_TYPES.FilteredPartners: {
            return (
              <PartnersSection
                key={component.id}
                listType={component.listType}
                tileType={component.tileType}
                sorting={component.sorting}
                loadMore={component.loadMore}
                partners={component.partners}
                clickLocation={CLICK_LOCATION.GPT_FILTERED_PARTNERS}
                filteredPartnersCategories={component.includedCategories}
                collectionMechanic={[]}
                reference={PARTNERS_SECTION_REFERENCE.GPT}
              />
            );
          }
          case CONTENT_TYPES.Superhero: {
            return (
              <SuperHero
                key={component.id}
                image={
                  component.heroImage && {
                    desktopUrl: component.heroImage.largeImage.url,
                    mobileUrl: component.heroImage.smallImage.url,
                    height: component.imageHeight,
                    title: component.heroImage.largeImage.title,
                    description: component.heroImage.largeImage.description,
                    imageObjectPosition: component.imageObjectPosition,
                  }
                }
                youtube={
                  component.youtubeId && {
                    youtubeID: component.youtubeId,
                    autoPlay: component.youtubeAutoPlay,
                    title: component.youtubeTitle,
                    videoHeight: component.youtubeVideoHeight,
                  }
                }
                titleCopyCtaBlock={component.titleCopyCtaBlock}
                titleCopyCtaBlockJustifyContent={
                  component.titleCopyCtaBlockJustifyContent
                }
                titleCopyCtaBlockAlignItems={
                  component.titleCopyCtaBlockAlignItems
                }
                titleCopyCtaBlockMaxWidth={component.titleCopyCtaBlockMaxWidth}
                titleCopyCtaBlockPaddingTop={
                  component.titleCopyCtaBlockPaddingTop
                }
                titleCopyCtaBlockPaddingBottom={
                  component.titleCopyCtaBlockPaddingBottom
                }
                titleCopyCtaBlockPaddingLeft={
                  component.titleCopyCtaBlockPaddingLeft
                }
                titleCopyCtaBlockPaddingRight={
                  component.titleCopyCtaBlockPaddingRight
                }
                opacityRgba={component.opacityRgba}
              />
            );
          }
          case CONTENT_TYPES.Accordion: {
            return (
              <Accordion
                key={component.id}
                isOpen={component.isOpen}
                title={component.title}
                className={styles['render-section__accordion']}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: markdownToHTML(component.body),
                  }}
                />
              </Accordion>
            );
          }

          case CONTENT_TYPES.FinancialServicesBanner: {
            const { bannerContent, showBanner, sys: id } = component;
            return showBanner && <VersatileCard key={id} {...bannerContent} />;
          }
          case CONTENT_TYPES.Button: {
            return <ButtonImage {...component} />;
          }
          case CONTENT_TYPES.MemberGetMember: {
            if (component.amexMGM && component.nextBestRetention) {
              return (
                <MemberGetMember
                  productTier={component.nextBestRetention}
                  {...component.amexMGM}
                  sectionDescription={component.ariaLabel}
                  terms={{ ...component.amexMGM.terms, ...component.terms }}
                  hasError={component.amexMGMHasError}
                />
              );
            }
            return null;
          }
          case CONTENT_TYPES.Card: {
            return <Card {...component} />;
          }

          case CONTENT_TYPES.CarouselFavourites: {
            return <CarouselFavouritesRecentlyViewed {...component} />;
          }

          default: {
            return null;
          }
        }
      })}
    </ContentGrid>
  );
};

const ContainerItemsRenderer = (containerItem: ContainerFormatted) => {
  return containerItem.items.map((component: any) => {
    // In the future, convert this if statement into a switch
    if (component.contentType === CONTENT_TYPES.Card) {
      return <Card key={component.id} {...component} />;
    }

    if (component.contentType === CONTENT_TYPES.TextBlock) {
      return <TextBlock key={component.id} {...component} />;
    }
    return null;
  });
};

const isVisibleForUserSession = (item: SectionFormatted) =>
  item.visibility === SECTION_VISIBILITY_FOR_SESSION.Both ||
  (item.visibility === SECTION_VISIBILITY_FOR_SESSION.LoggedIn &&
    item.isLoggedIn) ||
  (item.visibility === SECTION_VISIBILITY_FOR_SESSION.LoggedOut &&
    !item.isLoggedIn);

const isVisibleForUserFinancialCategory = (item: SectionFormatted) =>
  !item.financialSectionData.sectionFinancialCategoryTier ||
  (item.financialSectionData.userFinancialCategoryTier &&
    item.financialSectionData.sectionFinancialCategoryTier.includes(
      item.financialSectionData.userFinancialCategoryTier,
    ));
const contentSectionRenderer = ({
  sections,
}: ContentfulSectionRenderProperties) => {
  if (
    sections.length === 0 &&
    sections?.[0].contentType !== CONTENT_TYPES.Section
  ) {
    return null;
  }

  return (
    <>
      {sections.map((item: SectionFormatted) => {
        if (
          isVisibleForUserSession(item) &&
          isVisibleForUserFinancialCategory(item)
        ) {
          return (
            <Section
              key={item.id}
              className={styles['render-section']}
              backgroundColor={item?.backgroundColor ?? null}
              backgroundImage={item.backgroundImage}
              isFullBleed={item.isFullBleed}
              paddingTop={item.paddingTop}
              paddingBottom={item.paddingBottom}
              ariaLabel={item.ariaLabel}
            >
              {item.items.map(
                (contentRow: ContentRowFormatted | ContainerFormatted) => {
                  if (contentRow.contentType === CONTENT_TYPES.Container) {
                    const containerContent = contentRow as ContainerFormatted;
                    return (
                      <Container
                        key={containerContent.id}
                        variant={containerContent.variant}
                        layout={containerContent.layout}
                        alignContent={containerContent.alignContent}
                      >
                        {ContainerItemsRenderer(containerContent)}
                      </Container>
                    );
                  }
                  if (contentRow.contentType === CONTENT_TYPES.ContentRow) {
                    const sectionHasAccordion = contentRow.items.some(
                      (component: any) =>
                        component.contentType === CONTENT_TYPES.Accordion,
                    );

                    return (
                      <ContentRowRender
                        contentRow={contentRow}
                        key={contentRow.id}
                        isRow={contentRow.isRow}
                        alignContent={contentRow.alignContent}
                        isCarousel={contentRow.isCarousel}
                        carouselAutoPlay={contentRow.carouselAutoPlay}
                        carouselVariant={contentRow.carouselVariant}
                        contentSeparators={contentRow.contentSeparators}
                        extraPaddingBottom={contentRow.extraPaddingBottom}
                        className={classNames({
                          [styles['render-section__content-grid']]:
                            sectionHasAccordion,
                        })}
                      />
                    );
                  }
                  return null;
                },
              )}
            </Section>
          );
        }
        return null;
      })}
    </>
  );
};

const ContentfulRenderSection = (
  sections: ContentfulSectionRenderProperties,
) => {
  return contentSectionRenderer(sections);
};

export default ContentfulRenderSection;
