import classNames from 'classnames';
import {
  CONTAINER_VARIANT,
  ContainerProperties,
} from '@/components/integrated/container/container.types';
import styles from './container.module.scss';

const Container = ({
  variant,
  layout,
  alignContent,
  className,
  children,
}: ContainerProperties) => {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.container,
        styles[`container-${alignContent}`],
        className,
      )}
    >
      <div
        className={classNames(styles[`container-${variant}`], {
          [styles[`container--${layout}`]]: variant === CONTAINER_VARIANT.ROW,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
